form {
  display: flex;
  flex-direction: column;
}

input {
  width: 300px;
  padding: 18px;

  background-color: rgba(0, 139, 139, 0.5);
  color: #fff;
  font-size: 25px;

  border: none;
  border-radius: 5px;
}

input:hover {
  filter: opacity(0.5);
}

button {
  margin-top: 1rem;
  padding: 10px;

  background-color: darkcyan;
  font-size: 20px;
  color: #fff;

  border: none;
  border-radius: 5px;

  cursor: pointer;
}

button:hover {
  background-image: linear-gradient(rgba(0, 139, 139, 0.925), #fff);
  color: rgb(88, 119, 121);
  border: white solid 1px;
}
